(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbAccount.wbCompany.controller.CsnFileDownloadCtrl
   *
   * @description
   *
   */
  angular
    .module('wbAccount.wbCompany')
    .controller('CsnFileDownloadCtrl', CsnFileDownloadCtrl);

  function CsnFileDownloadCtrl(csnFiles, Company, LoggedUser) {
    var vm = this;

    vm.csnFiles = csnFiles;

    _.forEach(vm.csnFiles, function (csnFile) {
      csnFile.isProcessing = false;
    });

    /**
     * Download a selected csn file
     *
     * @param csnFile
     */
    vm.download = function download(csnFile) {
      var company = LoggedUser.getCompany();

      toggleProcessing(csnFile);

      Company
        .getCsnFile({id: csnFile.id, companyId: company.id})
        .$promise
        .then(function (fileData) {
          downloadTheFileViaBrowserApi(fileData.response, csnFile.name + '.xlsx');
        })
        .then(function () {
          toggleProcessing(csnFile);
        });
    };

    function toggleProcessing(csnFile) {
      csnFile.isProcessing = !csnFile.isProcessing;
    }

    function downloadTheFileViaBrowserApi(fileBlob, fileName) {
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(fileBlob, fileName);
      } else {
        var elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(fileBlob);
        elem.download = fileName;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }
    }
  }
}());
